import axios from 'axios';
import history from '../adapters/navigation/Routes/history';

const url = process.env.REACT_APP_API_URL;
const applicationId = process.env.REACT_APP_API_SECRET_KEY;

const postEmailPreferences = (props) => {
  const { email, newsletter, updates } = props;
  const body = {
    email,
    newsletter,
    updates,
    source: 'webform'
  };

  axios.defaults.baseURL = url;
  axios.defaults.headers = {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': applicationId
  };
  axios.post(url, body)
    .then((res) => {
      history.push('/thankyou');
    })
    .catch((error) => {
      history.push('/error');
      console.log('error', error);
    });
};

export default postEmailPreferences;
