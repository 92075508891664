import React from 'react';
import styled from 'styled-components';

function SearchContainer() {

  return (
    <SearchStyled>
      search
      <button id='toggle-search' className='nav-bar__button' type='button'>
        <svg
          width={29}
          height={29}
          xmlns='http://www.w3.org/2000/svg'
          data-svg='search'
        >
          <g fillRule='nonzero' />
        </svg>
      </button>
    </SearchStyled>
  );
}

// SearchContainer.PropTypes = {};
// SearchContainer.defaultProps = {};

const SearchStyled = styled.span`
  .nav-bar__button {
    min-width: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    touch-action: manipulation;
    &:focus {
      outline: 0;
    }
    &:first-child {
      &:last-child {
        margin-left: auto;
      }
    }
  }

  .nav-bar__button {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  .search {
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    bottom: 100%;
    z-index: 97;
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  .search--has-results {
    background-color: #fff;
    .search__input {
      color: #222;
    }
    .search__term {
      color: #222;
    }
  }

  .search__count {
    position: absolute;
    top: calc(100vh - 260px);
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    transition: opacity 150ms ease-out;
  }

  .search__count--is-visible {
    opacity: 1;
  }

  .search__footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 0 25px;
    padding-top: calc(100vh - 260px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    text-align: center;
  }

  .search__form {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .search__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 50px;
  }

  .search__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .search__input {
    position: relative;
    top: -65px;
    width: 100%;
    min-width: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    touch-action: manipulation;
    font-family: 'Canela', 'Times New Roman', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 63px;
    text-align: center;
    color: inherit;
    &:focus {
      outline: 0;
    }
    &::-webkit-input-placeholder {
      color: #7a7a7a;
    }
    &:-moz-placeholder {
      color: #7a7a7a;
    }
    &::-moz-placeholder {
      color: #7a7a7a;
    }
    &:-ms-input-placeholder {
      color: #7a7a7a;
    }
  }

  .search__term {
    min-width: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    touch-action: manipulation;
    font-family: 'Canela', 'Times New Roman', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 63px;
    text-align: center;
    color: inherit;
    position: relative;
    top: -65px;
    z-index: 2;
    width: auto;
    &:focus {
      outline: 0;
    }
    &::-webkit-input-placeholder {
      color: #7a7a7a;
    }
    &:-moz-placeholder {
      color: #7a7a7a;
    }
    &::-moz-placeholder {
      color: #7a7a7a;
    }
    &:-ms-input-placeholder {
      color: #7a7a7a;
    }
  }

  .search__mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #222;
    color: #fff;
    &:last-child {
      background-color: #fff;
      color: #222;
    }
  }

  .search__results {
    display: inline-flex;
    width: 100%;
    height: auto;
    max-width: 1624px;
    padding-bottom: 50px;
    text-align: left;
  }

  .search__term--is-hidden {
    display: none;
  }
`;

export default SearchContainer;
