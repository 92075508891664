import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const IconWithLabel = (props) => {
  const {
    buttonWrapper,
    children,
    className,
    label,
    labelAlign,
    _onClick,
  } = props;

  return buttonWrapper ? (
    <IconWithLabelStyled {...props}>
      <div
        onClick={_onClick}
        className={className}
      >
        {labelAlign === 'right'
          ? <>
            <span className='button__icon'>
              {children}
            </span>
            <span className='button__label'>
              {label}
            </span>
          </>
          : <>
            <span className='button__icon'>
              {children}
            </span>
            <span className='button__label'>
              {label}
            </span>
          </>
        }
      </div>
    </IconWithLabelStyled>

  ) : (
    <IconWithLabelStyled {...props }>
      <div
        onClick={_onClick}
        className={className}
      >
        <IconButton
          className={`icon ${className}`}
          color='inherit'
          aria-label={label}
          onClick={_onClick}
        >
          {labelAlign === 'right'
            ? <>
              <span className='icon__label'>
                {label}
              </span>
              {children}
            </>
            : <>
              {children}
              <span className='icon__label'>
                {label}
              </span>
            </>
          }
        </IconButton>
      </div>
    </IconWithLabelStyled>
  );
};

const IconWithLabelStyled = styled.span`
  text-align: left;
  
  .button__icon {
    float: left;
    top: 4px;
    height: 30px;
    position: relative;
    line-height: 35px;
  }

  .button__label {
    color: ${props => (props.labelColor
    ? props.labelColor
    : props.theme.buttonText)}
    line-height: 35px;
    position: relative;
    // bottom: 3px;
    margin-left: 12px;
    // bottom: ${props => props.marginBottom || '3px'}

  }

  .icon__label {
    color: ${props => (props.labelColor
    ? props.labelColor
    : props.theme.buttonText)}
    font-size: 14px;

    margin-bottom: 3px;
  }

`;

export default IconWithLabel;
