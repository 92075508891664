import React from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from '../../../config/_routes';
import { generateUuid } from '../../../lib/util';

import NoMatch from './NoMatch';

// TODO: update nested divs to styled-components
const Routes = () => (
  <Switch>
    {routes.map(route => (
      <Route
        key={generateUuid()}
        path={route.path}
        exact={route.exact}
        component={route.main}
      />
    ))}
    <Route component={NoMatch} />
  </Switch>
);
export default Routes;
