export const characterCountFontSize = '13px';
export const headingMargin = '14px';
export const imageMargin = '14px';
export const textMargin = '7px';
export const drawerWidth = '240rem';

export const fontSize = '14px';

// header is title appbar
export const headerFontSize = '18px';

// H1, H2, H3...
export const h1FontSize = '16px';
