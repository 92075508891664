import React from 'react';
import styled from 'styled-components';
import Routes from 'navigation/Routes/Routes';

const Main = () => (
  <MainStyled>
    <Routes />
  </MainStyled>
);

const MainStyled = styled.div`
  align-items: center;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.foreground};
  // display: flex;
  // flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 160px;

  @media only screen and (max-width: 768px) {
    padding-top: 30px;
  }
`;

export default Main;
