import React from 'react';
import propTypes from 'prop-types';

import {
  FormControl,
  InputLabel,
  Input as MaterialInput,
} from '@material-ui/core';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import InputAdornment from '../InputAdornment/InputAdornment';
// TODO: error handling for required, type
// TODO: Successfully saved message import { LoadingSpinner } from 'icons'
// TODO: loading, label, type: propTypes.oneOf(['text','date','number']),

const Input = ({
  autoComplete,
  autoFocus,
  className,
  disabled,
  errorMessage,
  icon,
  id,
  isError,
  label,
  name,
  onChange,
  required,
  type,
  value,
  ...rest
}) => (
  <ErrorBoundary componentName="Input">
    <FormControl className={className}>
      {label
        ? <InputLabel 
            htmlFor={name} 
            required={required} 
            error={isError} 
            shrink={true}
          >
          {label}
        </InputLabel>
        : ''
      }
      <MaterialInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        endAdornment={
          icon ? <InputAdornment>{icon}</InputAdornment> : ''
        }
        id={id}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        {...rest}
      />
    </FormControl>
    {isError ? <p className='error-message'>{errorMessage}</p> : ''}
  </ErrorBoundary>
);
Input.propTypes = {
  autoComplete: propTypes.oneOf([
    'on',
    'off',
  ]),
  autoFocus: propTypes.bool,
  className: propTypes.string,
  disabled: propTypes.bool,
  errorMessage: propTypes.string,
  icon: propTypes.string,
  id: propTypes.string,
  isError: propTypes.bool,
  label: propTypes.string,
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  required: propTypes.bool,
  type: propTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'text',
    'url',
  ]),
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
};


Input.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  className: null,
  disabled: false,
  errorMessage: null,
  icon: null,
  id: null,
  isError: false,
  label: null,
  name: null,
  required: false,
  type: 'text',
  value: '',
};

export default Input;
