import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

function LogoWord() {

  return (
    <LogoWordStyled>
      <a href='https://goodonyou.eco/' className='nav-bar__wordmark'>
        <svg viewBox='0 0 187 34' xmlns='http://www.w3.org/2000/svg'>
          <g fillRule='evenodd'>
            <path d='M10.08 15.8a3.64 3.64 0 0 1-4.83 0 3.5 3.5 0 0 1-.97-2.63c0-1.1.33-1.99.97-2.62a3.49 3.49 0 0 1 4.83 0c.65.63.97 1.52.97 2.62s-.32 2-.97 2.62M4.2 24.64c.54.04.97.08 1.33.08h3.67c2.06 0 3.06.92 3.06 2.76 0 2.03-1.54 3.4-4.57 3.4-3.02 0-4.68-1.4-4.68-3.51 0-1.18.4-2.1 1.2-2.73M15.9 5.38h-2.6a2.3 2.3 0 0 1-2.01 2.3c-.28.01-.59-.07-.81-.15l-.33-.14c-.78-.3-1.6-.45-2.5-.45-1.83 0-3.34.6-4.53 1.73a5.94 5.94 0 0 0-1.8 4.5c0 1.59.43 2.95 1.33 4.06-1.47.77-2.2 1.92-2.2 3.43 0 1.25.4 2.25 1.23 2.95A4.97 4.97 0 0 0 0 27.48c0 3.84 3.06 6.12 7.7 6.12 4.47 0 7.67-2.1 7.67-6.41 0-3.29-2.23-5.13-5.69-5.13H5.4c-1.37 0-2.05-.52-2.05-1.55 0-.81.4-1.4 1.19-1.8.9.44 1.94.66 3.13.66 1.83 0 3.34-.55 4.53-1.66a6.02 6.02 0 0 0 1.8-4.54c0-1.26-.28-2.35-.87-3.3a4.99 4.99 0 0 0 2.78-4.49' />
            <path d='M30.04 21.96a4.79 4.79 0 0 1-4.1 2 4.9 4.9 0 0 1-4.14-2 8.04 8.04 0 0 1-1.47-4.8c0-1.83.5-3.42 1.47-4.75a4.84 4.84 0 0 1 4.14-2.03c1.76 0 3.13.67 4.1 2.03a7.64 7.64 0 0 1 1.52 4.76c0 1.84-.5 3.43-1.52 4.8m-4.1-14.39c-2.63 0-4.71.96-6.33 2.84a10.14 10.14 0 0 0-2.38 6.75c0 2.62.8 4.9 2.38 6.79a7.91 7.91 0 0 0 6.33 2.8 7.8 7.8 0 0 0 6.3-2.8 10.08 10.08 0 0 0 2.41-6.8c0-2.6-.8-4.86-2.41-6.74a7.82 7.82 0 0 0-6.3-2.84' />
            <path d='M49.84 21.96a4.79 4.79 0 0 1-4.1 2 4.9 4.9 0 0 1-4.14-2 8.04 8.04 0 0 1-1.48-4.8c0-1.83.5-3.42 1.48-4.75a4.84 4.84 0 0 1 4.14-2.03c1.76 0 3.13.67 4.1 2.03a7.64 7.64 0 0 1 1.51 4.76c0 1.84-.5 3.43-1.5 4.8M45.73 7.57c-2.63 0-4.72.96-6.34 2.84a10.14 10.14 0 0 0-2.37 6.75c0 2.62.79 4.9 2.37 6.79a7.91 7.91 0 0 0 6.34 2.8 7.8 7.8 0 0 0 6.3-2.8 10.08 10.08 0 0 0 2.4-6.8c0-2.6-.78-4.86-2.4-6.74a7.82 7.82 0 0 0-6.3-2.84' />
            <path d='M65.57 23.96c-3.17 0-5.25-2.77-5.25-6.8 0-4.01 2.08-6.78 5.25-6.78 3.28 0 5.26 2.77 5.26 6.79s-1.98 6.79-5.26 6.79zm5-13.87c-.32-.59-1.04-1.14-2.08-1.7a6.78 6.78 0 0 0-3.2-.8c-5.19 0-8.07 3.83-8.07 9.58s2.88 9.59 8.06 9.59c2.16 0 4.4-1.15 5.3-2.58v2.28h3.02V.36h-3.03v9.73z' />
            <path d='M97 21.96a4.79 4.79 0 0 1-4.11 2 4.9 4.9 0 0 1-4.14-2 8.03 8.03 0 0 1-1.47-4.8c0-1.83.5-3.42 1.47-4.75a4.84 4.84 0 0 1 4.14-2.03c1.76 0 3.13.67 4.1 2.03a7.64 7.64 0 0 1 1.51 4.76c0 1.84-.5 3.43-1.5 4.8M92.88 7.57c-2.63 0-4.71.96-6.33 2.84a10.14 10.14 0 0 0-2.38 6.75c0 2.62.8 4.9 2.38 6.79a7.92 7.92 0 0 0 6.33 2.8 7.8 7.8 0 0 0 6.3-2.8 10.08 10.08 0 0 0 2.41-6.8c0-2.6-.8-4.86-2.41-6.74a7.82 7.82 0 0 0-6.3-2.84' />
            <path d='M114.2 7.58c-2.63 0-4.64 1.26-6.01 3.73V7.88h-3.02v18.58h3.02v-7.78c0-2.47.5-4.5 1.51-6 1.04-1.52 2.27-2.3 3.67-2.3 2.09 0 3.13 1.26 3.13 3.8v12.28h3.03V13.37c0-3.69-2.02-5.79-5.33-5.79' />
            <path d='M136.4 21.3l-4.6-13.42h-3.3l6.36 17.8-2.88 8.3h3.1l9-26.1h-3.17z' />
            <path d='M157.82 21.96a4.79 4.79 0 0 1-4.1 2 4.9 4.9 0 0 1-4.14-2 8.03 8.03 0 0 1-1.47-4.8c0-1.83.5-3.42 1.47-4.75a4.84 4.84 0 0 1 4.14-2.03c1.76 0 3.13.67 4.1 2.03a7.64 7.64 0 0 1 1.52 4.76c0 1.84-.5 3.43-1.52 4.8m-4.1-14.39c-2.63 0-4.71.96-6.33 2.84a10.14 10.14 0 0 0-2.38 6.75c0 2.62.8 4.9 2.38 6.79a7.92 7.92 0 0 0 6.33 2.8 7.8 7.8 0 0 0 6.3-2.8 10.08 10.08 0 0 0 2.41-6.8c0-2.6-.8-4.86-2.41-6.74a7.82 7.82 0 0 0-6.3-2.84' />
            <path d='M177.12 7.88v7.78c0 2.47-.5 4.5-1.55 6-1 1.52-2.23 2.3-3.63 2.3-2.1 0-3.13-1.26-3.13-3.8V7.88h-3.03v13.09c0 3.69 2.02 5.79 5.33 5.79 2.63 0 4.64-1.26 6-3.73v3.43h3.03V7.88h-3.02z' />
            <path d='M184.02 2.05v.52h.43c.22 0 .32-.09.32-.26s-.1-.26-.32-.26h-.43zm.89 2.13l-.74-1.12h-.15v1.12h-.51V1.56h.95c.53 0 .82.26.82.75 0 .4-.21.65-.55.72l.76 1.15h-.58zm-2.25-1.31c0 .52.16.96.48 1.3.32.36.73.53 1.24.53.5 0 .92-.17 1.24-.52.32-.35.48-.8.48-1.31 0-.52-.16-.96-.48-1.31a1.6 1.6 0 0 0-1.24-.52c-.51 0-.92.17-1.24.52-.32.35-.48.79-.48 1.3zm-.58 0c0-.68.22-1.25.66-1.71.43-.46.98-.69 1.64-.69.65 0 1.2.23 1.64.69.43.46.65 1.03.65 1.7 0 .69-.22 1.25-.65 1.72-.44.46-.99.68-1.64.68-.66 0-1.2-.22-1.64-.68a2.37 2.37 0 0 1-.66-1.71z' />
          </g>
        </svg>
      </a>
    </LogoWordStyled>
  );
}

// LogoWord.PropTypes = {};

// LogoWord.defaultProps = {};

const LogoWordStyled = styled.span`
  .nav-bar__wordmark {
    svg {
      width: 150px;

      @media only screen and (min-width: 768px) {
        width: 187px;
      }
    }
  }
`;

export default LogoWord;
