import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Checkbox } from 'ui';

const OptInCheckbox = props => (
  <OptInCheckboxStyled>
    <FormControlLabel
      label='Send me the Good On You newsletter with sustainable fashion tips, news about ethical brands and special offers'
      control={
        <Checkbox
          // icon={<FavoriteBorder />}
          // checkedIcon={<Favorite />}
          value='newsletter'
          checked={props.newsletter}
          onChange={props.handleCheckbox('newsletter')}
        />
      }
    />
    <br />
    <FormControlLabel
      label='Tell me about important service and app updates'
      control={
        <Checkbox
          // icon={<FavoriteBorder />}
          // checkedIcon={<Favorite />}
          value='updates'
          checked={props.updates}
          onChange={props.handleCheckbox('updates')}
        />
      }
    />
    <br />
    {/* <FormControlLabel
      label="."
      control={
        <div className="OptInCheckbox__link">
          <Checkbox
            label=" "
            // icon={<FavoriteBorder />}
            // checkedIcon={<Favorite />}
            value="termsandconditions"
            // checked
          />
          I agree to the&nbsp;
          <a href="TermsConditions">terms and conditions</a>
        </div>
      }
    /> */}
  </OptInCheckboxStyled>
);


const OptInCheckboxStyled = styled.div`
  font-size: ${props => props.theme.fontSize};

  .OptInCheckbox__link {
    /*color: rgba(0, 0, 0, 0.87);*/
    text-decoration: none;
  }
`;
;

export default OptInCheckbox;
