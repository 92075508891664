import React from 'react';
import ErrorPage from '../pages/Error/ErrorPage';
import ConfirmNewsletterPage from '../pages/ConfirmNewsletterPage/ConfirmNewsletterPage';
import GDPRSubscribeForm from '../pages/GDPRSubscribeForm/GDPRSubscribeForm';
import ThankyouPage from '../pages/Thankyou/ThankyouPage';

const routes = [
  {
    path: '/',
    exact: true,
    main: () => <GDPRSubscribeForm />,
    text: 'Home'
  },
  {
    path: '/confirm',
    main: () => <ConfirmNewsletterPage />,
    text: 'Confirm Newsletter'
  },
  {
    path: '/error',
    main: () => <ErrorPage />,
    text: 'Error'
  },
  {
    path: '/thankyou',
    main: () => <ThankyouPage />,
    text: 'Thankyou'
  }
];

export default routes;
