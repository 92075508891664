import React, { Component } from 'react';
// import PropTypes from 'prop-types'; TODO:
import { Button, Input } from 'ui';
import Link from '../../adapters/navigation/Link/Link';
import { parseRouter } from '../../lib/utils';
import CountryDropdown from '../../components/CountryDropdown/CountryDropdown';
import OptInCheckbox from '../../components/OptInCheckbox/OptInCheckbox';
import postNewsletter from '../../api/postNewsletter';
import styled from 'styled-components';
import history from '../../adapters/navigation/Routes/history';

export const email = parseRouter(history); // FIXME:
class ConfirmNewsletterPage extends Component {

  state = {
    countryShortcode: '',
    email,
    newsletter: false,
    updates: false,
    source: 'webform',
  };

  componentDidMount() {
    // FIXME: this.context.mixpanel.track('ConfirmNewsletterPage did mount.'); TODO
  }

    handleSubmit = this.handleSubmit.bind(this);
    handleChange = this.handleChange.bind(this);

    handleChange(event) {
      email
        ? this.setState({ [email]: email })
        : this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
      event.preventDefault();
      postNewsletter(this.state);
    }

  // TODO: refactor to be independent of state
  handleCheckbox = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  setCountryShortcode = code => {
    this.setState({
      countryShortcode: code
    });
  };

  render() {
    const { countryShortcode } = this.state;
    return (
      <ConfirmNewsletterPageStyled>
        <form noValidate autoComplete='off' onSubmit={this.handleSubmit}>
          <h1 className='subscribe-form__title'>
            Confirm your Newsletter preferences
          </h1>
          <div className='container--align'>
            <Input
              name='email'
              id='with-placeholder'
              label='Your email'
              placeholder='email@somewhere.com'
              value={this.state.email}
              fullWidth
              onChange={this.handleChange}
            />
          </div>

          <div className='container--align'>
            <CountryDropdown
              countryShortcode={countryShortcode}
              setCountryShortcode={this.setCountryShortcode}
              // TODO: (later) city={city}
            />
            <div className='subscribe-form__helper-text'>
              We need your country as our newsletter sends seasonal and
              location appropriate information
            </div>
          </div>

          <OptInCheckbox
            newsletter={this.state.newsletter}
            updates={this.state.updates}
            handleCheckbox={this.handleCheckbox}
          />
          <div className='subscribe__submit-button--wrapper container--align'>
            <Button
              variant='contained'
              size='large'
              onClick={this.handleSubmit}
              className='subscribe__submit-button'
            >
              Confirm
            </Button>
            <div className='subscribe-form__helper-text subscribe-form__helper-text--unsubscribe'>
              You can unsubscribe at any time
            </div>
          </div>
          <div className='container--align'>
            <div className='privacy-policy__link--text'>
              View our&nbsp;
              <Link
                className='privacy-policy__link--link'
                to='https://goodonyou.eco/privacy-policy/'
                newTab
              >
                privacy policy
              </Link>
            </div>
          </div>
        </form>
      </ConfirmNewsletterPageStyled>
    );
  }
}

const ConfirmNewsletterPageStyled = styled.div`
  margin: 0 20%;

  .privacy-policy__link--text {
    margin-top: 20px;
    font-size: ${props => props.theme.fontSize};
  }

  .subscribe-form__helper-text {
    font-size: 12px;
    color: #0000008a;
    margin: 8px auto;
  }

  .subscribe-form__helper-text--unsubscribe {
    // margin: 8px;
  }

  .subscribe-form__title {
    color: black;
    margin: 20px 30px 0;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 5%;

    .subscribe-form__title {
      font-size: 24px;
    }

    .subscribe__submit-button--wrapper {
      display: 'flex';
      flex-wrap: 'wrap';
    }
  }

  .subscribe__submit-button {
    background-color: ${props => props.theme.buttonBackground};
    padding: 8px 124px;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
    width: 100px;
  }

  .container--align {
    margin-left: 34px;
  }
`;

ConfirmNewsletterPage.contextTypes = {
  // mixpanel: PropTypes.object.isRequired,
};

export default ConfirmNewsletterPage;
