import React from 'react';
import styled from 'styled-components';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import HeaderContainer from '../_pageSections/Header/HeaderContainer';
import history from '../../adapters/navigation/Routes/history';
import { lightTheme } from '../../config/constants/themes';
import Main from '../_pageSections/Main/Main'; // TODO: refactor this CSS

import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';

mixpanel.init('972d8ff7c4a016f90e7112be8410189d');

// font-awesome import
library.add(fab, faCheckSquare, faCoffee);

/* eslint-disable no-unused-vars */
const App = () => (
  <ErrorBoundary>
    <MixpanelProvider mixpanel={mixpanel}>
      <Router history={history}>
        <ThemeProvider theme={lightTheme}>
          <AppStyled>
            <HeaderContainer />
            <Main />
          </AppStyled>
        </ThemeProvider>
      </Router>
    </MixpanelProvider>
  </ErrorBoundary>
);

const AppStyled = styled.span`
  font-family: 'Apercu', 'Roboto', Helvetica, Arial, sans-serif;
  text-align: left;
  font-weight: 400;
`;

export default App;
