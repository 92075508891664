import React from 'react';
import { Grid, Button} from 'ui';
import history from '../../adapters/navigation/Routes/history';
import './ErrorPage.css';

const ErrorPage = () => (
  <div className="container">
    <h1 className="error-page__title--error">
      Ummm...
    </h1>
    <h2 className="error-page__title">
      We couldn't find that email address
    </h2>
    <div className="error-page__social--container">
      <Grid container spacing={24}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="raised"
            size="large"
            onClick={() => history.push('/')}
            className="subscribe__submit-button"
          >
            Try&nbsp;again
          </Button>
        </Grid>
  
      </Grid>
    </div>
  </div>
);

export default ErrorPage;
