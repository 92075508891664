import uuidv4 from 'uuid/v4';

export const generateUuid = () => uuidv4();

export const objectToArray = object => Object.values(object);

export const capitalize = string => string[0].toUpperCase() + string.slice(1);


export const delay = (t, v) => (
  new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  })
);

// Usage for delay
// delay(500)
//   .then(() => {
// do stuff;
//   })
//   .then(() => delay(2000)
//     .then(() => {
// do stuff;
//     })
//     .then(() => delay(2000)
//       .then(() => {
// do stuff;
//       })))

export const getUrlLink = string => (
  !/^(ht)tps?:\/\//i.test(string) ? string : `http://${string}`
);
