import React from 'react';
import styled from 'styled-components';

import Loader from './Loader';

export const LoaderStyled = props => (
  <LoaderWrapper>
    <Loader {...props} />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  text-align: center;

  svg {
    color: ${props => props.theme.loader};
  }
`;

export default LoaderStyled;
