import React from 'react';

import styled from 'styled-components';
import InputField from './InputField/InputField';
import { textMargin } from '../../../config/constants/pageLayouts';

export const InputStyled = props => (
  <InputStyledWrapper>
    <InputField {...props} />
  </InputStyledWrapper>
);

const InputStyledWrapper = styled.div`
  margin-top: ${textMargin};

  input {
    width: 32em;
    @media only screen and (max-width: 768px) {
      width: 100%;
      min-width: 18em;
    }
  }

`;

export default InputStyled;
