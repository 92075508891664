import React, { Component } from 'react';
// import PropTypes from 'prop-types'; TODO:
import { Button, Input, } from 'ui';
import Link from '../../adapters/navigation/Link/Link';
import { parseRouter } from '../../lib/utils';
import OptInCheckbox from '../../components/OptInCheckbox/OptInCheckbox';
import postEmailPreferences from '../../api/postEmailPreferences';
import styled from 'styled-components';
import history from '../../adapters/navigation/Routes/history';

export const email = parseRouter(history); // FIXME: ?
class GDPRSubscribeForm extends Component {
  state = {
    email,
    newsletter: false,
    updates: false
  };

  componentDidMount() {
    // FIXME: this.context.mixpanel.track('GDPRSubscribeForm did mount.'); TODO
  }

    handleSubmit = this.handleSubmit.bind(this);
    handleChange = this.handleChange.bind(this);

    handleChange(event) {
      email
        ? this.setState({ [email]: email })
        : this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
      event.preventDefault();
      postEmailPreferences(this.state);
    }

  handleCheckbox = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    return (
      <GDPRSubscribeFormStyled>
        <form noValidate autoComplete='off' onSubmit={this.handleSubmit}>
          <h1 className='subscribe-form__title'>
            Confirm your email preferences
          </h1>
          {this.state.show && (
            <Input
              name='firstName'
              id='with-placeholder'
              label='Your first name'
              placeholder='First Name'
              margin='normal'
              fullWidth
              onChange={this.handleChange}
              // className="subscribe-form__input--wrapper"
            />
          )}
          {this.state.show && (
            <Input
              name='lastName'
              id='with-placeholder'
              label='Your surname'
              placeholder='Last Name'
              margin='normal'
              fullWidth
              onChange={this.handleChange}
              className='subscribe-form__input--wrapper'
            />
          )}
          <div className='container--align'>
            <Input
              name='email'
              id='with-placeholder'
              label='Your email'
              placeholder='email@somewhere.com'
              margin='normal'
              value={this.state.email}
              fullWidth
              onChange={this.handleChange}
            />
          </div>
          <OptInCheckbox
            newsletter={this.state.newsletter}
            updates={this.state.updates}
            handleCheckbox={this.handleCheckbox}
          />
          <div className="subscribe-form__helper-text container--align">
                (Leave both boxes blank to unsubscribe from both)
              </div>
          <div className='subscribe__submit-button--wrapper container--align'>
            <Button
              variant='contained'
              size='large'
              onClick={this.handleSubmit}
              className='subscribe__submit-button'
            >
              Confirm
            </Button>
            <div className='subscribe-form__helper-text subscribe-form__helper-text--unsubscribe'>
              You can unsubscribe at any time
            </div>
          </div>
          <div className='container--align'>
            <div className='privacy-policy__link--text'>
              View our&nbsp;
              <Link
                className='privacy-policy__link--link'
                to='https://goodonyou.eco/privacy-policy/'
                newTab
              >
                privacy policy
              </Link>
            </div>
          </div>
          {/* <FormSwitch /> */}
          {/* Internal only:
              <div className="formswitch__label">
                Subscribe form&nbsp;&nbsp;
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.show}
                      onChange={this.handleCheckbox('checkedGDPR')}
                      value="checkedGDPR"
                      color="primary"
                    />
                  }
                  label="GDPR only"
                />
                <Modal />
              </div> */}
        </form>
      </GDPRSubscribeFormStyled>
    );
  }
}

const GDPRSubscribeFormStyled = styled.div`
  margin: 0 20%;

  .privacy-policy__link--text {
    margin-top: 20px;
    font-size: ${props => props.theme.fontSize};
  }

  .subscribe-form__helper-text {
    font-size: 12px;
    color: #0000008a;
    margin: 8px auto;
  }

  .subscribe-form__helper-text--unsubscribe {
    // margin: 8px;
  }

  .subscribe-form__title {
    color: black;
    margin: 20px 30px 0;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 5%;

    .subscribe-form__title {
      font-size: 24px;
    }

    .subscribe__submit-button--wrapper {
      display: 'flex';
      flex-wrap: 'wrap';
    }
  }

  .subscribe__submit-button {
    background-color: ${props => props.theme.buttonBackground};
    padding: 8px 124px;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
    width: 100px;
  }

  .container--align {
    margin-left: 34px;
  }
`;
;

GDPRSubscribeForm.contextTypes = {
  // mixpanel: PropTypes.object.isRequired,
};

export default GDPRSubscribeForm;
