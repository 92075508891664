import React from 'react';
// import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import styled from 'styled-components';
import { 
  MenuItem,
  Input,
} from 'ui';

import countrys from '../../assets/location/country-iso-name-and-code';

const getCountrys = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : countrys.filter(country =>
      country.name.toLowerCase().slice(0, inputLength) === inputValue
  );

};

function renderSuggestion(country, { query, isHighlighted }) {
  const matches = match(country.name, query);
  const parts = parse(country.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (part.highlight ? (
          <span
            key={String(index)}
          >
            {part.text}
          </span>
        ) : (
            <strong
              key={String(index)}
            >
              {part.text}
            </strong>
          )))}
      </div>
    </MenuItem>
  );
}

const renderInputComponent = ({
  className,
  onChange,
  ref, 
  ...inputProps
}) => (
  <Input 
    autoComplete="off"
    className={className}
    fullWidth
    label='Your Country'
    name='country-dropdown-input'
    onChange={e => onChange(e)}
    required={false}
    {...inputProps} 
  />
);
class CountryDropdown extends React.Component {
  state = {
      value: '',
      countrys: [],
      countryShortcode: '',
    };
  
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    // TODO: WIP to fix the error here: 
    // event.target.value === "" ? console.log('empty'): console.log('empty');
    // this.state.value === ""
    // ? this.setState({
    //     countryShortcode: 'foo',
    //   })
    // : console.log('all good')
  };

  getCountryName = country => {
    this.props.setCountryShortcode(country.code)
    return(country.name)
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      countrys: getCountrys(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      countrys: [],
    });
  };

  render() {
    const { value, countrys } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      placeholder: 'type your country...'
    };
    return (
      <CountryDropdownStyled>
        <Autosuggest
          suggestions={countrys}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getCountryName}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
        />
      </CountryDropdownStyled>
    );
  }
}

const CountryDropdownStyled = styled.div`
  .react-autosuggest__suggestions-list {
    list-style-type: none
  }
`;

CountryDropdown.propTypes = {};

export default CountryDropdown;
