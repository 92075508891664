import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import LogoWord from './LogoWord';
import SearchContainer from './SearchContainer';
import NavbarLogo from './NavbarLogo';

function Navbar() {
  return (
    <NavbarStyled>
      <div
        className='nav-bar'
        // style={{ transform: 'translate3d(0px, 0px, 0px)' }}
      >
        <div className='nav-bar__inner'>
          <div className='nav-bar__group'>
            <NavbarLogo />
          </div>
          <div className='nav-bar__group'>
            <LogoWord />
          </div>
          <div className='nav-bar__group'>
            <a
              href='https://directory.goodonyou.eco'
              // target='_blank'
              className='nav-bar__link link'
            >
              Brand Directory
            </a>
            <SearchContainer />
          </div>
        </div>
      </div>
    </NavbarStyled>
  );
}

const NavbarStyled = styled.span`
  .nav-bar {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 0;
    line-height: 0;
    path {
      &:not([data-end]) {
        fill: #222;
      }
    }

    .nav-bar__inner {
      width: 100%;
      max-width: calc(100% - 50px);
      position: relative;
      display: flex;
      justify-content: center;

      @media only screen and (min-width: 768px) {
        max-width: calc(100% - 80px);
        justify-content: space-between;
      }

      @media only screen and (min-width: 1024px) {
        max-width: calc(100% - 60px);
      }

      @media only screen and (min-width: 1200px) {
        max-width: calc(100% - 80px);
      }

      .nav-bar__group {
        position: relative;
        z-index: 2;
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;

        &:first-child {
          display: none;
          justify-content: flex-start;
        }

        &:last-child {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          justify-content: space-between;
        }

        @media only screen and (min-width: 768px) {
          display: flex;
          flex: 1;

          &:first-child {
            display: flex;
          }

          &:last-child {
            position: static;
            flex-direction: row;
            justify-content: flex-end;
          }
        }
      }
    }

    .nav-bar__link {
      // display: none;
      display: inline-block;
      font-family: 'Apercu', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .link {
      line-height: 1.5em;
      background-image: linear-gradient(to right, #222 0%, #222 100%);
      background-size: 100% 1px;
      background-position: left bottom;
      background-repeat: repeat-x;
      text-decoration: none;
      color: #222;
      transition: background-position 125ms linear;

      &:hover {
        background-position: left bottom 0.55em;
        transition: background-position 125ms
          cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

    .nav-bar--light {
      .nav-bar__link {
        background-image: linear-gradient(to right, #fff 0%, #fff 100%);
        color: #fff;
      }
      path {
        &:not([data-end]) {
          fill: #fff;
        }
      }
    }
  }

  // .nav-bar__button {
  //   min-width: 0;
  //   padding: 0;
  //   border: 0;
  //   border-radius: 0;
  //   background-color: transparent;
  //   -webkit-appearance: none;
  //   cursor: pointer;
  //   touch-action: manipulation;
  //   &:focus {
  //     outline: 0;
  //   }
  //   &:first-child {
  //     &:last-child {
  //       margin-left: auto;
  //     }
  //   }
  // }
`;

export default Navbar;
