import React from 'react';
import { Grid, Col, Row } from 'ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ThankyouPage = () => (
  <ThankyouPageStyled>
    <h1 className='thankyou-page__title--thankyou'>Thank you!</h1>
    <h2 className='thankyou-page__title'>
      Help your friends make a difference every time they shop
    </h2>
    <div className='thankyou-page__social--container'>
      <Grid>
        <Row>
          <Col width={[1, 1 / 2, 1 / 4]} m={3}>
            <a
              href='https://www.facebook.com/goodonyouapp'
              className='thankyou-page__social--link'
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} size='2x' />
            </a>
          </Col>
          <Col width={[1, 1 / 2, 1 / 4]} m={3}>
            <a href='https://twitter.com/goodonyou_app'>
              <FontAwesomeIcon
                icon={['fab', 'twitter']}
                size='2x'
                className='thankyou-page__social--link'
              />
            </a>
          </Col>
          <Col width={[1, 1 / 2, 1 / 4]} m={3}>
            <a href='https://www.instagram.com/goodonyou_app/'>
              <FontAwesomeIcon
                icon={['fab', 'instagram']}
                size='2x'
                className='thankyou-page__social--link'
              />
            </a>
          </Col>
        </Row>
      </Grid>
    </div>
  </ThankyouPageStyled>
);

export default ThankyouPage;

const ThankyouPageStyled = styled.div`
  .Thankyou__header {
    height: 58px;
    margin: 10px;
  }

  .thankyou-page__title {
    font-size: 2em;
    position: relative;
    text-align: center;
  }

  .thankyou-page__title--thankyou {
    font-size: 3em;
    position: relative;
    text-align: center;
  }

  .thankyou-page__social--container {
    max-width: 300px;
    text-align: center;
    margin: 50px auto;
    left: 0;
    right: 0;
  }
  .thankyou-page__social--link {
    text-decoration: none;
    color: #0000008a;
  }
`;