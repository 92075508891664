import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const ButtonStyled = props => (
  <ButtonStyledWrapper>
    <Button {...props} />
  </ButtonStyledWrapper>
);

const ButtonStyledWrapper = styled.span`
  button {
    color: ${props => props.theme.buttonText};
    background-color: ${props => props.theme.buttonBackground};
    margin: 3px;
  }

  button:hover {
    background-color: ${props => props.theme.buttonHoverBackground};
    color: ${props => props.theme.buttonHoverText};
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
`;

export default ButtonStyled;
