import React from 'react';
import propTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const isExternal = to => window.location.host !== to.host;

const Link = ({
  to,
  className,
  children,
  newTab,
}) => (
    isExternal(to) ? (
      <a
        className={className}
        href={to}
        target={newTab ? '_blank' : null}
      >
        {children}
      </a>
    ) : (
        <RouterLink
          className={className}
          to={to}
        >
          {children}
        </RouterLink>
      )
  );

Link.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  newTab: propTypes.bool,
  to: propTypes.string.isRequired,
};

Link.defaultProps = {
  className: '',
  newTab: false,
};


export default Link;
