import React from 'react';
import styled from 'styled-components';

function NavbarLogo() {

  return (
    <NavbarLogoStyled>
        <div className='nav-bar__logo' >
        <a href="https://goodonyou.eco/" className="nav-bar__logo">
          <svg 
            width="35" 
            height="29"   
            xmlns="http://www.w3.org/2000/svg" 
            data-svg="logo"
          >
            <path 
              d="M26.76 21.82c-2.68 2.65-6 4.12-9.37 4.12-3.72 0-7.45-1.73-10.26-4.73A15.6 15.6 0 0 1 2.91 9.4c.39-3.99 2.94-6.57 6.5-6.57a6.19 6.19 0 0 1 6 3.97c.27.6.51 1.25.76 1.9.65 1.66 1.31 3.38 2.37 4.9a8.63 8.63 0 0 0 10.59 2.86c.52-.23 1.06-.5 1.59-.8-.85 2.3-2.2 4.41-3.96 6.16M25.45 2.83c3.4.08 6.08 2.9 6.3 6.5 0 .34-.03.77-.16 1.2-.55 1.75-2.06 2.64-3.63 3.35a5.75 5.75 0 0 1-7.07-1.9c-.77-1.1-1.52-2.29-1.89-3.79-.03-.2-.01-.39.01-.55a6.44 6.44 0 0 1 6.44-4.8M25.52 0a9.37 9.37 0 0 0-8.17 4.35A9.01 9.01 0 0 0 9.4 0C4.35 0 .59 3.67.06 9.15a18.43 18.43 0 0 0 4.97 13.98c3.35 3.58 7.85 5.64 12.36 5.64 4.13 0 8.18-1.76 11.4-4.95a19.86 19.86 0 0 0 5.84-14.05C34.62 4.41 30.61.12 25.52 0" 
              fillRule="evenodd"
            />
          </svg>
        </a>
      </div>
    </NavbarLogoStyled>
  );

}

const NavbarLogoStyled = styled.span`

`;

export default NavbarLogo;
