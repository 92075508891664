import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Navbar from './Navbar';

function Header() {
  return (
    <HeaderStyled>
      <div className='header-bar'>
        <Navbar />
      </div>
    </HeaderStyled>
  );
}

// Header.PropTypes = {};

// Header.defaultProps = {};

const HeaderStyled = styled.span`

  .header-bar {
    height: 60px;
  }

  .header-bar__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  overflow: hidden;
}

.header--dark {
  background-color: #222;
  overflow: visible;
  color: #fff;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    width: 100vw;
    background-color: #222;
  }
  .header__heading {
    padding: 0 50px;
    margin: auto 0;
  }
  .header__inner {
    height: 80vh;
    max-height: 500px;
    padding: 50px 0 0;
  }
}

.header--404 {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    width: 100vw;
    background-color: #222;
    background-color: #f2684b;
  }
  overflow: visible;
  color: #fff;
  .header__inner {
    height: 80vh;
    max-height: 500px;
  }
}

.header--parallax {
  min-height: 600px;
  .header__heading {
    max-width: 240px;
  }
}

.header__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.header__bottom {
  position: absolute;
  bottom: 0;
  align-self: center;
  margin-bottom: 25px;
}

.header__categories {
  width: 100%;
  height: 0;
}

.header__category-toggle {
  min-width: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  touch-action: manipulation;
  font-family: 'Apercu', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: inherit;
  transition: color 125ms cubic-bezier(.215, .61, .355, 1);
  &:focus {
    outline: 0;
  }
  &:hover {
    color: #c8c7c8;
  }
  &::before {
    content: 'Show ';
  }
  &::after {
    content: 'Categories';
  }
}

.header__category-toggle--active {
  &::before {
    content: 'Hide ';
  }
}

.header__date {
  display: none;
}

.header__image {
  width: 100%;
  margin-left: 25px;
}

.header__image--full {
  width: auto;
  height: 100%;
}

.header__image-container {
  width: calc(100% - 50px);
  float: left;
  margin-left: 25px;
  height: 100%;
  margin-top: 75px;
  font-size: 0;
  line-height: 0;
}

.header__inline {
  display: inline-block;
  width: 0;
}

.header__inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 0 25px;
}

.header__mask {
  width: 100%;
  overflow: hidden;
}

.header__tagline {
  position: absolute;
  left: 25px;
  bottom: 0;
  width: 100%;
  font-family: 'Apercu', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}
`;

  // .Header {
  //   align-items: center;
  //   display: flex;
  //   flex-direction: column;
  //   flex-grow: 1;
  //   justify-content: center;
  //   width: 100%;

  //   .Header__AppBar {
  //     background-color: ${props => props.theme.headerBackground};
  //     bottom: auto;
  //     left: 0;
  //     top: 0;
  //     white-space: nowrap;

  //     .Header--grow {
  //       flex-grow: 1;
  //     }
      
  //     .Header__logo {
  //       width: 24px;
  //       height: 20px;
  //       margin-right: 10px;
  //       margin-top: 5px;
  //     }

  //     .Header__menuDescription {
  //       color: ${props => props.theme.headerText};
  //       margin-right: 5px;
  //     }

  //     .Header__menu--button {
  //       margin-left: -12;
  //       margin-right: 20;
  //       color: ${props => props.theme.headerText};
  //     }

  //     .Header__title--centerWrapper {
  //       text-align: center;
  //       width: 100%;
  //       color: ${props => props.theme.headerText};
  //       .Header__title--brand { 
  //         white-space: nowrap;
  //         color: ${props => props.theme.headerText};
  //       }
  //     }

  //     .Header__title--edwina {
  //       font-size: 16px; 
  //       margin-top: 5px;
  //       color: ${props => props.theme.headerText};
  //     }

  //   }
  // }


export default Header;
